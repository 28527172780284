/* eslint-disable max-classes-per-file */
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'RendezVousButton',
  template: require('./RendezVousButton.html'),
  components: {},
})
export default class RendezVousButton extends Vue {
  protected bookingUrl(): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return process.env.VUE_APP_BOOKING_URL!;
  }
}
