export default {
  // 'en-US': {
  //   currency: {
  //     style: 'currency',
  //     currency: 'USD',
  //   },
  // },
  'fr-FR': {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
  },
};
