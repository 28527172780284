import { Options, Vue } from 'vue-class-component';
import {
  BIconGeoAltFill,
  BIconTelephoneFill,
  BIconEnvelopeFill,
} from 'bootstrap-icons-vue';

/** Router */
import { RoutesPaths } from '@/router/routes-paths';

@Options({
  name: 'Footer',
  template: require('./Footer.html'),
  components: { BIconGeoAltFill, BIconTelephoneFill, BIconEnvelopeFill },
})
export default class FooterSection extends Vue {
  public RoutesPaths: any = RoutesPaths;

  protected displayedTelNumber(): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return process.env.VUE_APP_DISPLAYED_CONTACT_TEL!;
  }

  protected contactTelNumber(): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return process.env.VUE_APP_CONTACT_TEL!;
  }

  protected displayedAddress(): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return process.env.VUE_APP_ADDRESS!;
  }

  protected googleMapAddress(): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return process.env.VUE_APP_GMAP!;
  }

  protected pageJauneAddress(): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return process.env.VUE_APP_PAGE_JAUNE!;
  }
}
