import { Vue, Options, setup } from 'vue-class-component';
import { useMeta } from 'vue-meta';

/** Components */
import TabBar from '@/components/Section/TabBar/TabBar';
import FooterSection from '@/components/Section/Footer/Footer';

/** Utils */
import { DEFAULT_LOCALE } from '@/utils/I18N/I18N';

@Options({
  name: 'App',
  template: require('./App.html'),
  components: {
    TabBar,
    FooterSection,
  },
})
export class App extends Vue {
  public meta = setup(() =>
    useMeta({
      title: '',
      htmlAttrs: { lang: DEFAULT_LOCALE, amp: true },
    }),
  );
}
