/* eslint-disable max-classes-per-file */
import { Options, Vue } from 'vue-class-component';

import { RoutesPaths } from '@/router/routes-paths';

class Props {
  public label!: string;
  public firstLetterColor!: string;
}

@Options({
  name: 'ColorFirstLetterSpan',
  template: require('./ColorFirstLetterSpan.html'),
  components: {},
})
export default class ColorFirstLetterSpan extends Vue.with(Props) {
  public RoutesPaths: any = RoutesPaths;

  public getRoute(index: number): string {
    return Object.keys(RoutesPaths)[index];
  }

  protected separatedWords(): string[] {
    return this.label.split(' ');
  }

  protected firstLetter(word: string): string {
    return word.length > 0 ? word[0] : '';
  }

  protected slicedWord(word: string): string {
    const res = word.slice(1);
    return res.length > 0 ? res : '';
  }

  protected getFirstLetterColor(): string {
    return this.firstLetterColor;
  }
}
