/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
export const slidersI18nFr = {
  slider1: {
    imagePath: '/images/toit.jpeg',
    title1: '',
    title2: 'A votre service',
    subtitle: 'depuis 2013',
    description: '2023 - Beaujolais',
  },
  slider2: {
    imagePath: '/images/toit2.jpeg',
    title1: 'Intervention',
    title2: 'dans un rayon de',
    subtitle: '20km',
    description: '2023 - Beaujolais',
  },
  slider3: {
    imagePath: '/images/ramonage.png',
    title1: 'Entretien, ramonage',
    title2: 'et expertise ',
    subtitle: "de l'installation",
    description: '2023 - Beaujolais',
  },
  slider4: {
    imagePath: '/images/vieille-cheminee.png',
    title1: 'Pensez à effectuer',
    title2: 'votre entretien',
    subtitle: 'dès la fin de la saison de chauffe',
    description: "C'est la garantie d'avoir un appareil prêt pour l'hiver!",
  },
};
