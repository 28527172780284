import { createI18n } from 'vue-i18n';

import messages from './messages';
import dateTimeFormats from './dateTimeFormats';
import numberFormats from './numberFormats';

const LOCALE_FR = 'fr';
export const DEFAULT_LOCALE = LOCALE_FR;
export type SUPPORTED_LANGUAGE_CODES = typeof LOCALE_FR;

/** 18N */
// let locale = navigator.language;
// try {
//   locale = locale.split('-')[0];
// } catch (error) {
//   locale = DEFAULT_LOCALE;
// }
export const i18n = createI18n({
  locale: 'fr',
  messages,
  dateTimeFormats,
  numberFormats,
});

export const setLanguage = (language: SUPPORTED_LANGUAGE_CODES): void => {
  try {
    const newLocale = language.split('-')[0];
    i18n.global.locale = newLocale as any;
  } catch (error) {
    i18n.global.locale = DEFAULT_LOCALE;
  }
};
