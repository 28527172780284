/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
export const servicesI18nFr = {
  service1: {
    imagePath: '/images/poil-a-bois.jpeg',
    title: 'Ramonage',
    subtitle: 'Insert ou poêle à bois',
    price: '65\xa0€',
    description:
      '👉 ramonage du conduit de fumée<br/>👉 ramonage du conduit de raccordement<br/>👉 démontage et nettoyage du foyer<br/>👉 évacuation et retraitement des suies',
  },
  service2: {
    imagePath: '/images/poil-a-granule.jpeg',
    title: 'Ramonage',
    subtitle: 'Insert ou poêle à granulés',
    price: '130\xa0€',
    description:
      '👉 ramonage du conduit de fumée<br/>👉 ramonage du conduit de raccordement<br/>👉 démontage et nettoyage du foyer, des ventilateurs, du moteur de vis et de la carte électronique<br/>👉 remise à zéro des compteurs (si cela est possible)<br/>👉 évacuation et retraitement des suies',
  },
  service3: {
    imagePath: '/images/chaudiere-a-granule.jpeg',
    title: 'Ramonage',
    subtitle: 'Chaudière à granulés',
    price: '160\xa0€',
    description:
      '👉 ramonage du conduit de fumée<br/>👉 ramonage du conduit de raccordement<br/>👉 démontage et nettoyage du foyer, des ventilateurs, du moteur de vis et de la carte électronique<br/>👉 remise à zéro des compteurs (si cela est possible)<br/>👉 évacuation et retraitement des suies',
  },
  service4: {
    imagePath: '/images/chaudiere-fioul.jpg',
    title: 'Ramonage',
    subtitle: 'Chaudière fioul',
    price: '150\xa0€',
    description:
      '👉 ramonage du conduit de fumée<br/>👉 ramonage du conduit de raccordement<br/>👉 démontage et nettoyage du foyer<br/>👉 démontage du brûleur avec remplacement gicleur et nettoyage du filtre gasoil<br/>👉 test de combustion avec un analyseur de gaz<br/>👉 évacuation et retraitement des suies',
  },
  service5: {
    imagePath: '/images/test-fumigene.png',
    title: 'Test fumigène',
    subtitle: "Teste l'étanchéité de votre conduit",
    price: '150\xa0€',
    description:
      "👉 Indispensable en cas de doute ou à la suite d'un feu de conduit, le test fumigène teste<br/>👉 l'étanchéité de votre conduit et permet de déceler une éventuelle fuite.<br/>👉 Pour cela, j'utilise une cartouche créant une fumée dense et odorante.",
  },
  service6: {
    imagePath: '/images/debouchage-de-conduit.jpeg',
    title: 'Débouchage de conduit de fumée',
    subtitle: 'Suite à la formation de bistre ou en cas de nids',
    price: '150\xa0€',
    description:
      "👉 En cas de conduit bouché, suite à la formation de bistre ou en cas de nids de guêpes/abeilles/oiseaux, une intervention spécifique est alors obligatoire pour retrouver l'usage complet de votre conduit.",
  },
  service7: {
    imagePath: '/images/inspection-camera.jpeg',
    title: 'Inspection caméra',
    subtitle: 'Inspection de la totalité du conduit',
    price: '150\xa0€',
    description:
      "👉 En cas de doute, une inspection de la totalité du conduit, avec une caméra spécifique, permettra de valider la conformité de l'installation.<br/>👉 Cette opération permettra de localiser un bouchon, une anomalie dans l'installation, etc...",
  },
  service8: {
    imagePath: '/images/debistrage-de-conduit.jpeg',
    title: 'Débistrage de conduit',
    subtitle: "En cas de risque d'incendie majeur.",
    price: 'sur devis',
    description:
      "👉 Cette prestation est obligatoire avant un tubage, ou lorsqu'une installation présente un risque d'incendie majeur. <br/>👉 Il s'agit d'une opération lourde réalisé à l'aide d'une débistreuse pneumatique équipée de chaînes rotatives, qui viennent casser le goudron dans le conduit.",
  },
};
