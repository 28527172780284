import { Options, Vue } from 'vue-class-component';

/** Router */
import { RoutesPaths } from '@/router/routes-paths';

/** Components */
import RendezVousButton from '@/components/Custom/RendezVousButton/RendezVousButton';
import ColorFirstLetterSpan from '@/components/Generic/ColoredFirstLetterSpan/ColorFirstLetterSpan';
import styles from '@/assets/css/variables.module.scss';

@Options({
  name: 'TabBar',
  template: require('./TabBar.html'),
  components: { ColorFirstLetterSpan, RendezVousButton },
})
export default class TabBar extends Vue {
  public RoutesPaths: any = RoutesPaths;

  public getRoute(index: number): string {
    return Object.keys(RoutesPaths)[index];
  }

  protected isSelectedNavItem(navItemPath: RoutesPaths): boolean {
    return navItemPath === this.$route.fullPath;
  }

  protected getMainColors(): string {
    return styles.primaryColor;
  }
}
