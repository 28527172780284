export enum RoutesPaths {
  HomePage = '/',
  CompanyPage = '/presentation.html',
  RegulationPage = '/reglementation.html',
  DefinitionPage = '/role-du-ramonage.html',
  ServicesPage = '/tarifs.html',
  ContactPage = '/contact.html',
  PartnersPage = '/partners.html',
  Noncompliance = '/non-conformite.html',
  Service1Page = '/services/ramonage-insert-ou-poele-a-bois.html',
  Service2Page = '/services/ramonage-insert-ou-poele-a-granules.html',
  Service3Page = '/services/ramonage-chaudiere-a-granules.html',
  Service4Page = '/services/ramonage-chaudiere-fioul.html',
  Service5Page = '/services/test-etancheite-fumigene.html',
  Service6Page = '/services/debouchage-de-conduit-de-fumee.html',
  Service7Page = '/services/inspection-camera.html',
  Service8Page = '/services/debistrage-de-conduit.html',
}
