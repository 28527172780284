/** Content */
import { slidersI18nFr } from '@/Content/Carousel-i18n';
import { partnersI18nFr } from '@/Content/Partners-i18n';
import { servicesI18nFr } from '@/Content/Services-i18n';
import { nonComplianceI18nFr } from '@/Content/Noncompliance-i18n';

/** Components */
import { carouselI18nFr } from '@/components/Generic/CarouselSection/Carousel-i18n';

/* eslint-disable */
export default {
  fr: {
    companyName: 'Beaujolais Ramonage Service',
    tabBar: {
      home: 'Accueil',
      aboutUs: 'Qui suis-je ?',
      contact: 'Contact',
      regulation: 'Règlementation',
      sweeping: 'Le ramonage',
      services: 'Mes tarifs',
      rendezVous: '🗓️ Prendre rendez-vous',
    },
    footer: {
      companyCaption:
        "Professionnel diplômé d'état en ramonage. Ramonage chaudière, poêle et insert.",
      navigation: 'Navigation',
      contact: 'Contact',
      discover: 'Decouvrir',
      emailForm: 'Contact',
      home: 'Accueil',
      aboutUs: 'Qui suis-je ?',
      contactUs: 'Me contacter',
      services: 'Mes tarifs',
      partners: 'Mes partenaires',
      pageJauneReviews: 'Avis les pages jaunes',
      googleReviews: 'Avis google',
      regulation: 'Règlementation / Non-conformité',
      sweeping: 'Le ramonage',
    },
    copyright: {
      text: 'Copyright © 2023 B.R.S Tous droits réservés',
    },
    contact: {
      formTitle: 'Formulaire de contact',
      name: 'Nom',
      mail: 'Votre adresse électronique',
      message: 'Message',
      send: 'Envoyer',
      mailSubject: 'Envoi de Formulaire CONTACT',
      success: {
        title: 'Merci 👍 !',
        text: 'Votre message est envoyé.',
      },
      reminder: 'Si vous souhaitez <b>fixer un rendez-vous</b>, il vous suffit de <b>cliquer directement sur le bouton</b> de prise de rendez-vous <i><b>"Prendre rendez-vous"</b></i> ci-dessous.<br>C\'est aussi simple que cela !',
      otherwise: "Pour toute autre question, veuillez utiliser le formulaire ci-dessous :"
    },
    carousel: carouselI18nFr,
    carouselItemsData: slidersI18nFr,
    home: {
      carousel: {
        service: 'Service',
      },
      company: {
        aboutTheCompany: 'A propos de B.R.S',
        text1:
          'Entreprise spécialisée en fumisterie et chauffage au bois, dirigée par un professionnel diplômé d\'État en tant que "ramoneur fumiste", avec plus de 10 années d\'expérience.',
        text2:
          'Découvrez mes services de ramonage professionnels : débistrage, débouchage, test fumigène et ramonage de chaudières, inserts et poêles. Je suis disponibles pour répondre à toutes vos questions via mon formulaire de contact.',
      },
      services: {
        ourServices: 'Mes tarifs',
        someServices:
          'Mon entreprise offre une gamme de services comprenant notamment',
      },
    },
    definition: {
      title: 'Role du ramonage',
      definition:
        "Le ramonage est une opération technique consistant à nettoyer l'intérieur des conduits d'évacuation des fumées d'appareils de chauffage au bois, tels que cheminées, poêles et chaudières.<br><br>L'objectif est d'éliminer les dépôts de suie, de cendres et de créosote qui s'accumulent au fil du temps, afin d'assurer un bon tirage, une combustion efficace et sécurisée, tout en prévenant les risques d'incendie, d'intoxication et d'usure prématurée des installations.<br><br>Le ramonage est souvent une exigence légale et une mesure essentielle pour garantir le bon fonctionnement, la performance énergétique et la durabilité de ces équipements de chauffage au bois.",
      title2: "Les enjeux d'un bon ramonage",
      definition2:
        "<b>Sécurité:</b> Un conduit obstrué peut entraîner un risque d'incendie en raison de l'accumulation de matières inflammables dans les conduits.<br><br><b>Performance énergétique :</b> Un conduit propre permet un meilleur tirage, assurant ainsi une combustion plus efficace du bois et une utilisation optimale de l'énergie.<br><br><b>Durabilité de l'installation :</b> Un ramonage régulier prolonge la durée de vie des appareils de chauffage en réduisant l'usure due à l'encrassement.<br><br><b>Prévention des intoxications :</b> Un conduit bouché peut provoquer le refoulement des fumées toxiques à l'intérieur du domicile, mettant en danger la santé des occupants.<br><br><b>Assurances :</b> Le ramonage régulier est souvent une condition imposée par les compagnies d'assurance pour couvrir les risques liés aux incendies.<br><br><b>RSDT :</b> La \"Règlementation Sanitaire Départementale Type\" du Rhône préconise une fréquence du ramonage de deux fois par an pour les combustibles solides et une fois par an pour le gaz.<br><br><b>Respect de l'environnement :</b> Un conduit propre réduit les émissions de particules fines et de gaz nocifs dans l'atmosphère.<br/><br/><b>confort thermique :</b> un appareil bien entretenu assure une meilleur diffusion de la chaleur, en effet 1mm de suie entraine jusqu'a 7% de consommation supplémentaire.<br><br><b>Tranquillité d'esprit :</b> Savoir que ses installations de chauffage sont entretenues de manière professionnelle offre une tranquillité d'esprit aux propriétaires et aux occupants du domicile.",
    },
    services: {
      title: 'Tous mes tarifs',
      subtitle: 'Mon entreprise propose les services suivants',
    },
    serviceDetail: {
      info: '<i>Les tarifs comprennent le déplacement, la prestation et le retraitement des déchets.<br/>Une facture et un certificat de ramonage sont délivrés.</i>',
    },
    company: {
      description1:
        "BRS, créée en 2013, est une entreprise spécialisée dans le ramonage avec une expérience de plus de 10 ans dans ce domaine.<br/>Le fondateur est <b>diplômé d'état \"ramoneur fumiste\"</b>. Le siège social se situe au Perréon (69460) dans le Beaujolais.<br/><br/>je propose une gamme complète de services, comprenant le <b>ramonage de chaudières, d'inserts et de poêles</b> fonctionnant au fioul, au bois ou à granulés, le débistrage de conduit, le débouchage de conduit de fumée, ainsi que le test fumigène.<br/><br/>Je propose mes services dans un rayon de <b>20 km autour du Perréon (69460)</b>, couvrant les départements du Rhône\xa0(69), de Saône-et-Loire\xa0(71) et de l'Ain\xa0(01).<br/><br/>Pour la facilité des clients, différents moyens de paiement sont acceptés, tels que les <b>espèces</b>, les <b>chèques</b>, le <b>virement bancaire</b>, les <b>cartes bancaires</b>, ainsi que les paiements en <b>Bitcoin</b>. <br/><br/>Pour toute demande d'information n'hésitez pas à me contacter. Je me tiens à votre disposition pour vous offrir un service de qualité adapté à vos attentes.",
      description2:
        "Ma zone d'activité couvre les communes suivantes :<br/><br/>- Belleville-en-Beaujolais (69220)<br/>- Anse (69480)<br/>- Limas (69400)<br/>- Beaujeu (69430)<br/>- Villefranche-sur-Saône (69400)<br/>- Lamure-sur-Azergues (69870)<br/>- Quincié-en-Beaujolais (69430)<br/>- Arnas (69400)<br/>- Chambost-Allières (69870)<br/>- Chamelet (69620)<br/>- Le Bois-d'Oingt (69620)<br/>- Jassans-Riottier (01480)<br/>- Saint-Jean-d'Ardières (69220)",
    },
    servicesData: servicesI18nFr,
    generic: {
      seeMore: 'Voir plus',
    },
    partners: {
      title: 'Mes partenaires',
      website: 'Site web',
    },
    partnersData: partnersI18nFr,
    error: {
      genericTitle: 'Oups !',
      genericText: 'Une erreur est survenue',
    },
    noncompliance: {
      title: 'Règlementation / non-conformités',
      footer:
        "* <b>Piège à calories :</b> phénomène de hausse anormale de température. Le piège à calorie constitue un danger.<br/><br/>Les réglementations en vigueur sont déterminées par le DTU 24.1, 24.2 et le CSTB. Je suis tenu, non seulement d'entretenir votre appareil de chauffe, mais également de vous informer de toute non- conformité apparente. Ceci dans l'unique but de garantir votre sécurité.<br/><br/>Un bon ramonage s'effectue par le haut, sauf si l’accès au toit est vraiment impossible. L’accès à la souche permet d'identifier certains défauts indécelables d'en bas.",
    },
    nonComplianceData: nonComplianceI18nFr,
    meta: {
      home: "Découvrez les services professionnels de ramonage et de fumisterie proposés par BRS (Beaujolais Ramonage Service). Spécialisés dans l'entretien des cheminées, chaudières et poêles, j'offre des prestations de qualité dans le Beaujolais et les départements du Rhône, de Saône-et-Loire et de l'Ain. Débistrage, débouchage, test fumigène, ramonage de chaudières fioul, à granulés, inserts et poêles. Faites moi confiance pour un service professionnel et fiable.",
      company:
        "BRS est une entreprise spécialisée dans le ramonage et la fumisterie. Forts de plus de 10 ans d'expérience, je propose des services professionnels d'entretien de cheminées, chaudières et poêles. Basés dans le Beaujolais, j'interviens dans les départements du Rhône, de Saône-et-Loire et de l'Ain. Mes services comprennent le débistrage, le débouchage, les tests fumigènes, ainsi que le ramonage de chaudières fioul, à granulés, d'inserts et de poêles. Faites moi confiance et bénéficiez d'un service de qualité pour l'entretien optimal de vos installations.",
      contact:
        "Contactez moi pour toutes vos questions et demandes de renseignements. Spécialistes du ramonage et de la fumisterie dans le Beaujolais, je propose des services professionnels d'entretien de cheminées, chaudières et poêles. J'interviens dans les départements du Rhône, de Saône-et-Loire et de l'Ain. Utilisez mon formulaire de contact pour me joindre facilement. Je suis diplômée et je me tiens à votre disposition pour vous fournir des réponses précises et un service personnalisé.",
      sweeping:
        "Découvrez la définition et l'importance du ramonage pour l'entretien de vos cheminées, chaudières et poêles. Cette pratique essentielle consiste à éliminer les dépôts de suie et de résidus, assurant ainsi un fonctionnement sûr et efficace de vos installations. BRS, spécialistes du ramonage dans le Beaujolais, vous offre des services professionnels et qualifiés pour garantir la performance et la sécurité de vos équipements. Explorez ma page pour en savoir plus sur les bénéfices du ramonage et contactez-moi pour bénéficier de mes services.",
      partners:
        "Découvrez les partenaires professionnels de confiance de BRS. Mes partenaires partagent mes valeurs d'excellence et d'intégrité, et travaillent pour offrir des solutions complètes de qualité. Découvrez mes partenaires et bénéficiez d'une expertise complémentaire pour répondre à vos besoins.",
      regulation:
        "Découvrez les régulations en vigueur en France concernant le ramonage des cheminées, chaudières et poêles. Cette page vous informe sur les obligations légales et les normes de sécurité liées à l'entretien de vos installations de chauffage. Comprenez les exigences légales en matière de ramonage et découvrez comment BRS, spécialistes du ramonage dans le Beaujolais, répond aux normes et réglementations en offrant des services conformes et professionnels. Restez informé des obligations légales pour assurer la sécurité et le bon fonctionnement de vos équipements de chauffage.",
      services:
        "Découvrez les services professionnels de ramonage et de fumisterie offerts par BRS. Je propose une gamme complète de prestations pour l'entretien de vos cheminées, chaudières et poêles. Mes services comprennent le débistrage de conduit, le débouchage de conduit de fumée, les tests fumigènes, ainsi que le ramonage de chaudières fioul, à granulés, d'inserts et de poêles. Faites moi confiance pour des services professionnels et fiables dans le Beaujolais. Découvrez mes services et assurez le bon fonctionnement et la sécurité de vos installations de chauffage.",
      noncompliance:
        "Découvrez les points de non-conformité qu'un ramoneurs professionnels doit signaler lors de l'inspection et de l'entretien de vos cheminées, chaudières et poêles. Identifiez les problèmes potentiels et assurez la sécurité et la conformité de vos installations de chauffage. BRS, spécialistes du ramonage dans le Beaujolais, vous offre des services de qualité et vous guide dans la résolution des non-conformités pour un fonctionnement optimal de vos équipements.",
    },
  },
};
