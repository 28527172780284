import {
  createRouter,
  // createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router';

/** Contents */
import { brsServicesData, ServiceData } from '@/Content/ServicesData';

/** Router */
import { RoutesPaths } from '@/router/routes-paths';

const HomePage = () => import('@/views/Home/HomePage');
const CompanyPage = () => import('@/views/Company/CompanyPage');
const DefinitionPage = () => import('@/views/Definition/DefinitionPage');
const ServicesPage = () => import('@/views/Services/ServicesPage');
const ContactPage = () => import('@/views/Contact/ContactPage');
const PartnersPage = () => import('@/views/Partners/PartnersPage');
const ServiceDetailPage = () =>
  import('@/views/ServiceDetail/ServiceDetailPage');
const NoncompliancePage = () => import('@/views/Noncompliance/Noncompliance');

const getServicesRouteRecord = (): RouteRecordRaw[] =>
  brsServicesData.map(
    (serviceData: ServiceData) =>
      ({
        path: serviceData.routerPath,
        component: ServiceDetailPage,
        name: `Service-${serviceData.routerPath}`,
      } as RouteRecordRaw),
  );

const routes: RouteRecordRaw[] = [
  { path: RoutesPaths.HomePage, component: HomePage, name: 'Home' },
  { path: RoutesPaths.CompanyPage, component: CompanyPage, name: 'Company' },
  {
    path: RoutesPaths.DefinitionPage,
    component: DefinitionPage,
    name: 'Definition',
  },
  { path: RoutesPaths.ServicesPage, component: ServicesPage, name: 'Services' },
  { path: RoutesPaths.ContactPage, component: ContactPage, name: 'Contact' },
  {
    path: RoutesPaths.PartnersPage,
    component: PartnersPage,
    name: 'Partenaires',
  },
  {
    path: RoutesPaths.RegulationPage,
    component: NoncompliancePage,
    name: 'Noncompliance',
  },
  ...getServicesRouteRecord(),
  {
    path: '/:pathMatch(.*)*',
    redirect: RoutesPaths.HomePage,
  },
];

const router = createRouter({
  history: createWebHistory(RoutesPaths.HomePage),
  routes,
  scrollBehavior: (_to: any, _from: any, _savedPosition: any) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    ({ left: 0, top: 0 } as any),
});

export default router;
