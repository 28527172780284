export const partnersI18nFr = {
  partner1: {
    name: 'M Vernus Ludovic',
    description: '🌲 Aménagement et entretien de jardins et de parcs 🌳',
    mobile: '06 03 00 18 69',
    address: '69460 LE PERREON',
    url: 'https://www.pagesjaunes.fr/pros/56714352',
  },
  partner2: {
    name: 'M Cabessa Nicolas',
    description: '🧰 BMS - Beaujolais Multi Service 📋',
    mobile: '07 45 25 13 93',
    address: '69430 MARCHAMPT',
    services: ` 
    <ul>
      <li>
        Nettoyage de toitures
      </li>
      <li>
        Nettoyage de terrasses
      </li>
      <li>
        Plomberie
      </li>
      <li>
        Électricité
      </li>
      <li>
        Autres services sur demande
      </li>
    </ul>
`,
  },
  partner3: {
    name: 'M Lafond Anthony',
    description: '🔥 Bois de chauffage 🌳',
    mobile: '06 88 01 12 28',
    address: '69460 VAUX-EN-BEAUJOLAIS',
  },
  partner4: {
    name: 'M Pinto Raphaël',
    description:
      '💻 Développement de site web, applications mobiles et logiciels 📱',
    mobile: '06 74 28 37 24',
    address: '69220 CHARENTAY',
    url: 'https://www.linkedin.com/in/rapha%C3%ABl-pinto-16791057/',
  },
};
