/** Utils */
import { i18n } from '@/utils/I18N/I18N';

/** Router */
import { RoutesPaths } from '@/router/routes-paths';

export interface ServiceData {
  imagePath: string;
  title: string;
  subtitle: string;
  price: string;
  routerPath: string;
  description: string;
}

export const brsServicesData: ServiceData[] = [
  {
    imagePath: i18n.global.t('servicesData.service1.imagePath'),
    title: i18n.global.t('servicesData.service1.title'),
    subtitle: i18n.global.t('servicesData.service1.subtitle'),
    price: i18n.global.t('servicesData.service1.price'),
    routerPath: RoutesPaths.Service1Page,
    description: i18n.global.t('servicesData.service1.description'),
  },
  {
    imagePath: i18n.global.t('servicesData.service2.imagePath'),
    title: i18n.global.t('servicesData.service2.title'),
    subtitle: i18n.global.t('servicesData.service2.subtitle'),
    price: i18n.global.t('servicesData.service2.price'),
    routerPath: RoutesPaths.Service2Page,
    description: i18n.global.t('servicesData.service2.description'),
  },
  {
    imagePath: i18n.global.t('servicesData.service3.imagePath'),
    title: i18n.global.t('servicesData.service3.title'),
    subtitle: i18n.global.t('servicesData.service3.subtitle'),
    price: i18n.global.t('servicesData.service3.price'),
    routerPath: RoutesPaths.Service3Page,
    description: i18n.global.t('servicesData.service3.description'),
  },
  {
    imagePath: i18n.global.t('servicesData.service4.imagePath'),
    title: i18n.global.t('servicesData.service4.title'),
    subtitle: i18n.global.t('servicesData.service4.subtitle'),
    price: i18n.global.t('servicesData.service4.price'),
    routerPath: RoutesPaths.Service4Page,
    description: i18n.global.t('servicesData.service4.description'),
  },
  {
    imagePath: i18n.global.t('servicesData.service5.imagePath'),
    title: i18n.global.t('servicesData.service5.title'),
    subtitle: i18n.global.t('servicesData.service5.subtitle'),
    price: i18n.global.t('servicesData.service5.price'),
    routerPath: RoutesPaths.Service5Page,
    description: i18n.global.t('servicesData.service5.description'),
  },
  {
    imagePath: i18n.global.t('servicesData.service6.imagePath'),
    title: i18n.global.t('servicesData.service6.title'),
    subtitle: i18n.global.t('servicesData.service6.subtitle'),
    price: i18n.global.t('servicesData.service6.price'),
    routerPath: RoutesPaths.Service6Page,
    description: i18n.global.t('servicesData.service6.description'),
  },
  {
    imagePath: i18n.global.t('servicesData.service7.imagePath'),
    title: i18n.global.t('servicesData.service7.title'),
    subtitle: i18n.global.t('servicesData.service7.subtitle'),
    price: i18n.global.t('servicesData.service7.price'),
    routerPath: RoutesPaths.Service7Page,
    description: i18n.global.t('servicesData.service7.description'),
  },
  {
    imagePath: i18n.global.t('servicesData.service8.imagePath'),
    title: i18n.global.t('servicesData.service8.title'),
    subtitle: i18n.global.t('servicesData.service8.subtitle'),
    price: i18n.global.t('servicesData.service8.price'),
    routerPath: RoutesPaths.Service8Page,
    description: i18n.global.t('servicesData.service8.description'),
  },
];
