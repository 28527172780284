/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
export const nonComplianceI18nFr = {
  noncompliance1: {
    title: "Absence de plaque d'étanchéitéAbsence de plaque d'étanchéité :",
    description:
      "La plaque d'étanchéité limite l'intrusion des animaux ainsi que l'accumulation de suie entre le conduit maçonné et le tubage. En effet, cela peut provoquer un feu de conduit.",
  },
  noncompliance2: {
    title: 'Absence de chapeau sur un tubage :',
    description:
      "Le chapeau de protection est obligatoire sur les conduits tubés, et recommandé sur les conduits maçonnés.<br/>Il limite l'infiltration d'eau dans l'appareil de chauffage.",
  },
  noncompliance3: {
    title: "Absence d'embout de finition (DTU 2021) :",
    description:
      "L'embout de finition a pour seul but de préserver les mains délicates de votre ramoneur favori.",
  },
  noncompliance4: {
    title: 'Absence du collier de soutien :',
    description:
      "Il sert à maintenir le tubage, afin que celui-ci ne repose pas sur l'appareil de chauffe.",
  },
  noncompliance5: {
    title: 'Absence de ventilation haute :',
    description:
      'Elle permet de ventiler le tubage en cas de feu de cheminée. Elle mesure au minimum 5cm2.',
  },
  noncompliance6: {
    title: 'Hauteur de la souche au faîtage insuffisante :',
    description:
      "Pour éviter tout risque de refoulement de gaz dans l'habitation, la souche doit dépasser d'au moins 40 cm du faîtage.",
  },
  noncompliance7: {
    title: "Arrivée d'air :",
    description:
      "Tout système de chauffage doit disposer de sa propre arrivée d'air, ceci afin d'éviter les intoxications au monoxyde de carbone.<br/>Les aérations de VMC ainsi que les grands volumes de pièces ne sont pas suffisants pour alimenter la combustion en oxygène, n'en déplaise aux installateurs.",
  },
  noncompliance8: {
    title: 'Distances de sécurité du conduit de fumée:',
    description:
      "Le conduit de fumée doit être distant d'au minimum 10 cm des matériaux combustibles, ou 8cm si le conduit est isolé.<br/>Si cette distance ne peut être respectée, un écran de protection type silicate de calcium peut être installé.",
  },
  noncompliance9: {
    title: 'Distances de sécurité du conduit de raccordement :',
    description:
      "Le conduit de raccordement doit être distant d'au minimum 3 fois son diamètre des matériaux combustibles, ou 1,5 fois son diamètre avec une protection adaptée.",
  },
  noncompliance10: {
    title: 'Absence de ventilation basse du conduit :',
    description:
      'La ventilation basse permet de ventiler le tubage en cas de feu de cheminée. Elle mesure au minimum 20cm2.',
  },
  noncompliance11: {
    title: 'Présence de goudron dans le conduit :',
    description:
      "La présence de goudron est synonyme de défaillance sur l'installation ou de sa mauvaise utilisation : hygrométrie du bois trop élevée, conduit de fumée inadapté (trop large, trop froid), installation mal dimensionnée. Le risque de feu de conduit est élevée.",
  },
  noncompliance12: {
    title: 'Tubage installé dans le mauvais sens :',
    description:
      "Le sens de montage du tubage doit être respecté. A défaut, celui-ci se verra fortement dégradé à l'utilisation et pendant le ramonage.",
  },
  noncompliance13: {
    title: 'Dévoiement du conduit de raccordement :',
    description:
      'La totalité des dévoiements ne doit pas dépasser 90° : 1 coude à 90° ou 2 coudes à 45°.',
  },
  noncompliance14: {
    title: 'Réduction sur conduit de raccordement :',
    description:
      "Une réduction ne peut être située sur la buse de l'appareil, mais uniquement entre le conduit de raccordement et le conduit de fumée.",
  },
  noncompliance15: {
    title: 'Absence du T de ramonage :',
    description:
      "Lorsque l'appareil est raccordé en face arrière, l'installation d'un T de ramonage est obligatoire. Celui-ci évite l'obstruction du conduit, qui peut provoquer un refoulement de fumée dans l'habitation.",
  },
  noncompliance16: {
    title: 'Ventilation de la hotte insuffisante sur un insert :',
    description:
      "La hotte de votre insert doit comporter les ventilations suivantes : 500cm2 en partie haute et 400cm2 en partie basse. Ceci afin d'éviter un piège à calories*.<br/>Lorsque les matériaux combustibles sont présents au plafond, la hotte doit être surmontée d'un caisson de décompression avec 2 grilles de 20cm2 chacune.",
  },
  noncompliance17: {
    title: 'Ventilation coffrage conduit de fumée :',
    description:
      "Le coffrage doit être ventilé en haut et en bas à l'aide de grilles, avec un passage d'air d'au moins 20cm, ceci afin d'éviter les pièges à calories*.",
  },
  noncompliance18: {
    title: 'Zone de débouché du conduit :',
    description:
      "Un poêle, un insert à bois ou un poêle à granulés non étanche doivent obligatoirement être installés en zone 1, c'est à dire que la souche doit dépasser d'au minimum 40cm du faîtage.<br/>Les poêles ou chaudières à granulés étanches peuvent être en zone 2 (-40 cm au dessus du faîtage) ou zone 3 (en façade).",
  },
  noncompliance19: {
    title: 'Parabole ou antenne :',
    description:
      'Il est interdit de fixer une antenne ou une parabole à une souche métallique.',
  },
};
